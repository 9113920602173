<div class="setbox padd15">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <div class="form-group">
        <label>预约号</label>
        <input [(ngModel)]="req.id" class="form-control"
               placeholder="请输入预约号">
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <div class="form-group">
        <label>手机号</label>
        <input [(ngModel)]="req.contacts_phone" class="form-control"
               placeholder="请输入手机号">
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2">
      <div class="form-group">
        <label>是否已分配</label>
        <div class="border-0">
          <select class="w-100" [(ngModel)]="req.allocation">
            <option value="">全部</option>
            <option value="true">是</option>
            <option value="false">否</option>
          </select>
        </div>
      </div>

    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-3 ">
      <div class="form-group">
        <label class="w-100 d-web">&nbsp;</label>
        <button class="btn btn-info  " type="button" (click)="search(1)">
          <i class="fa fa-search"></i>搜索
        </button>
        <button class="btn btn-secondary " type="button" (click)="clear()">
          <i class="fa fa-times"></i>清空条件
        </button>
        <button (click)="downloadData(1,index)" class="btn btn-warning" type="button" >
          <i class="fa fa-download"></i> 下载
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="progress > 0">数据已加载{{progress}}%</div>
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th>预约号</th>
      <th>客户名称</th>
      <th>带看管家</th>
      <th>看房时间</th>
      <th class="show2">城市</th>
      <th class="show2">社区</th>
      <th class="show2">意向房型</th>
      <th class="show2">预约提交时间</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>

    <tr
      *ngIf="!btn_privileges.SEE_HOUSE_LIST || resp?.list == null || resp?.list.length == 0">
      <td class="show1" colspan="5">本次未查询到相关数据</td>
      <td class="show2" colspan="9">本次未查询到相关数据</td>
    </tr>

    <ng-container *ngIf="btn_privileges.SEE_HOUSE_LIST">
      <ng-container *ngFor="let info of resp?.list | paginate: {itemsPerPage: req.page_size,
          currentPage: req.page_num,totalItems: resp.total }">
        <tr>
          <td>{{ info.id }}</td>
          <td>{{ info.contacts_name }}</td>
          <td>{{ info.sys_user_name ? info.sys_user_name : '未分配' }}</td>
          <td>{{ info.appointment_time }} {{ formatAppointTime(info.start_time) }}
            -{{ formatAppointTime(info.end_time) }}
          </td>
          <td class="show2">{{ info.city_name }}</td>
          <td class="show2">{{ info.project_name }}</td>
          <td class="show2">{{ info.group_name }}</td>
          <td class="show2">{{ info.create_time | date:'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>
            <div>
              <button *ngIf="btn_privileges.SEE_HOUSE_OPERATE && info.dispose"
                      (click)="deal(info)"
                      type="button" class="btn btn-info mr10">
                <i class="fa fa-cogs"></i>处理
              </button>
              <button *ngIf="btn_privileges.SEE_HOUSE_OPERATE && !info.dispose"
                      (click)="detail(info)"
                      type="button" class="btn btn-info mr10">
                <i class="fa fa-eye"></i>查看
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
  <div
    *ngIf="btn_privileges.SEE_HOUSE_LIST && resp?.list != null && resp?.list.length > 0"
    class="text-right pages">
    <app-page (pageChangeEvent)="pageChange($event)"></app-page>
    <div class="text-right">共有{{ resp.total }}条信息</div>
  </div>
</div>
