import {Component, OnInit} from '@angular/core';
import {
  AppointmentHouseApplyListDTO,
  AppointmentHouseApplyListRequest,
  AppointmentHouseApplyListResponse
} from "../../../model/appoint/appoint.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";
import {AppointService} from "../../../service/appoint/appoint.service";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit {

  btn_privileges: any = {
    'SEE_HOUSE_LIST': false,
    'SEE_HOUSE_OPERATE': false,
    'APPOINT_ROOM_WAIT_VIEW': false,
    'WSEE_HOUSE_OPERATE': false,
  };


  req: AppointmentHouseApplyListRequest = {
    page_num: 1,
    page_size: 20,
    allocation: ''
  };
  resp: AppointmentHouseApplyListResponse;
  downloadRes;
  loading = false;
  progress = 0;
  index = 1;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private workService: WorkService,
    private idempotentService: IdempotentService,
    private appointService: AppointService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.search();
  }


  search(page?) {
    this.index++;
    if (page) {
      this.req.page_num = page;
    }

    this.progress = 0;
    this.appointService.appointmentList(this.req).then(data => {
      if (data && data.list) {
        this.resp = data;
      } else {
        this.resp = {total: 0, list: []};
      }
    });
  }

  pageChange($event: number) {
    this.req.page_num = $event;
    this.search();
  }

  clear() {
    this.req = {
      page_num: 1,
      page_size: 20,
      allocation: ''
    };
    this.search();
  }

  formatAppointTime(str: string) {
    return str ? str.substring(0, str.length - 3) : '';
  }


  deal(info: AppointmentHouseApplyListDTO) {
    this.router.navigate(['/system_manage/todolist/detail', info.work_remind_id]);
  }

  detail(info: AppointmentHouseApplyListDTO) {
    this.router.navigate(['../detail', info.id], {relativeTo: this.activatedRoute});
  }

  downloadData(page,index) {
    if (page === 1 && this.loading) {
      return;
    }
    if(index === this.index){
      this.loading = true;
      let req = {
        page_num: page,
        page_size: 100,
        allocation: this.req.allocation,
        contacts_phone: this.req.contacts_phone,
        id: this.req.id
      };

      this.appointService.appointmentList(req).then(data => {
        if (req.page_num === 1 && data?.list?.length > 0) {
          this.downloadRes = data.list;
        } else if (data?.list?.length > 0) {
          this.downloadRes = this.downloadRes.concat(data.list);
        }
        if (data.total > 0) {
          this.progress = Math.floor(this.downloadRes.length / data.total * 100);
        }
        if (data.total > 0 && data.total > this.downloadRes.length && data?.list?.length > 0) {
          req.page_num++;
          this.downloadData(req.page_num,index);
        } else {
          this.progress = 100;
          this.downloadFile();
        }
      }).finally(() => {
        this.loading = false;
      });
    }else{
      this.progress = 0;
    }

  }

  downloadFile() {
    if (this.downloadRes) {
      let header = ["预约号", "客户名称", "带看管家", "看房时间", "城市", "社区", "意向房型", "预约提交时间"];
      let data = [];
      data.push(header);
      if (this) {
        for (let info of this.downloadRes) {
          let list = [];
          list.push(info.id);
          list.push(info.contacts_name);
          list.push(info.sys_user_name ? info.sys_user_name : '未分配');
          list.push(info.appointment_time + " " + this.formatAppointTime(info.start_time) + "-" + this.formatAppointTime(info.end_time));
          list.push(info.city_name);
          list.push(info.project_name);
          list.push(info.group_name);
          list.push(new Date(info.create_time));
          data.push(list);
        }
      }
      let wd = XLSX.utils.aoa_to_sheet(data);
      let wk = XLSX.utils.book_new();
      let num = Math.ceil(Math.random() * 10000);
      XLSX.utils.book_append_sheet(wk, wd);
      XLSX.writeFile(wk, "预约看房_" + num + ".xlsx", {cellStyles: true});
    }
  }


}
